.simple-red-button {
    background-color: #ff007f;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 5px 0px #004aad;
    transition: all 0.2s ease;
    outline: none;
}

.simple-red-button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px #004aad;
}
