.random-end-game-container  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95vh;
    padding: 1rem;
    box-sizing: border-box;
    align-items: center;
}

.random-end-game-container ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 20px 20px;
    background-color: #f4f4f4;
    background-image: 
        linear-gradient(to right, #9ab6d1 1px, transparent 1px), 
        linear-gradient(to bottom, #9ab6d1 1px, transparent 1px); 
    z-index: -1;
}

.stats {
    background-color: #8ab1df;
    border-radius: 15px;
    width: 90%;
    max-width: 300px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); 
    margin-bottom: 20px;
    margin-top: auto; 
}

.stats h2 {
    font-size: 24px;
    color: white; 
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;
}

.stats .stats-facts p {
    font-size: 18px;
    color: white; 
    margin-left: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.stats-facts {
    margin-bottom: 20px;
}

.buttons-container-random-end-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: auto; 
}

.buttons-container-random-end-game button {
    width: 200px;
    height: 50px;
}
