.huge-ad-banner {
    width: 100%;
    height: 200px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-align: center;
    margin: 1rem 0;
}
