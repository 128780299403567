.shop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    padding: 65px 15px 15px 15px;
    box-sizing: border-box;
}

.shop-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 20px 20px;
    background-color: #f4f4f4;
    background-image: 
        linear-gradient(to right, #9ab6d1 1px, transparent 1px),
        linear-gradient(to bottom, #9ab6d1 1px, transparent 1px); 
    z-index: -1;
}

.shop-content {
    background-color: #8ab1df;
    border-radius: 15px;
    padding: 20px;
    max-width: 400px;
    width: 85%;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    position: relative;    
    height: 70vh;
    overflow-y: auto;
}
