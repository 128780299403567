.leaderboard-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    padding: 65px 15px 15px 15px;
    box-sizing: border-box;
}

.leaderboard-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 20px 20px;
    background-color: #f4f4f4;
    background-image:
        linear-gradient(to right, #9ab6d1 1px, transparent 1px),
        linear-gradient(to bottom, #9ab6d1 1px, transparent 1px);
    z-index: -1;
}

.leaderboard-page .content-center {
    width: 85%;
    margin: 0 auto;
    background-color: #8ab1df; 
    border-radius: 20px;
    padding: 5px 23px 10px 23px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 75vh;
    max-width: 400px;
}

.leaderboard-page .title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin: 0;
}

.leaderboard-page .subtitle {
    font-size: 14px;
    text-align: center;
    color: white;
    margin: 6px 0 6px 0;
}

.leaderboard-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 62vh;
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #fb7185 transparent; /* Firefox */
}

.leaderboard-list::-webkit-scrollbar {
    width: 8px;
}

.leaderboard-list::-webkit-scrollbar-track {
    background: transparent;
}

.leaderboard-list::-webkit-scrollbar-thumb {
    background-color: #fb7185;
    border-radius: 4px;
}

.leaderboard-entry {
    font-size: 15px;
    color: white;
    background-color: #3b82f6; /* Default blue for entries */
    margin-bottom: 10px;
    padding: 6px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leaderboard-entry.highlight {
    background-color: #fb7185; /* Pink background for the current user */
}

.leaderboard-entry.ellipsis {
    color: white;
    justify-content: center;
}

.leaderboard-entry-position {
    margin: 0;
}

.leaderboard-entry-name {
    margin: 0;
    display: inline-block;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.leaderboard-entry-score {
    margin: 0;
}

.ellipsis-title {
    margin: 0;
}