.initial-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
}

.initial-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 20px 20px; 
    background-color: #f4f4f4;
    background-image: 
        linear-gradient(to right, #9ab6d1 1px, transparent 1px), 
        linear-gradient(to bottom, #9ab6d1 1px, transparent 1px); 
    z-index: -1;
}

.settings-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.buttons-container button {
    width: 200px;
    height: 50px;
}

.logo-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dots-logo {
    width: 85%;
}

.language-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.language-button {
    background-color: #4ab8ff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 5px 0px #004aad;
    transition: all 0.2s ease;
    outline: none;
}

.language-button:active {
    transform: translateY(5px);
    box-shadow: 0px 0px 0px #004aad;
}

.language-button .fa-globe {
    margin-right: 4px;
}